export function countdown(seconds) {
  let handler = {};
  let destroyed = false;
  function tick() {
    if (destroyed) {
      if (handler.onDestory) {
        handler.onDestory();
      }
      return;
    }
    if (seconds > 0) {
      if (handler.onTick) {
        handler.onTick(seconds--);
      }
      setTimeout(tick, 1000);
    } else {
      if (handler.onEnd) {
        handler.onEnd();
      }
    }
  }
  setTimeout(function () {
    if (handler.onStart) {
      handler.onStart();
    }
    tick();
  });
  handler.updateSecond = function(newSecond) {
      let oldSec = seconds
      seconds = newSecond;
      if (oldSec <= 0) {
        tick();
      }
  };

  handler.destory = function(){
    destroyed = true;
  };

  handler.onEnd = function(){
    console.log('end')
  }
  return handler;
}

// 准备倒计时  3000 秒

// hd.onStart = () => {
//   console.log('start');
// }

// 每隔一秒 调用一次 onTick  传入参数 倒计时的 second
// hd.onTick = (second) => {
//   console.log(second);
// }

//倒计时停止
// hd.onEnd = () => {
//   console.log('end')
// }

// beforeDestroy() {
//   // 销毁倒计时
//   hd.destroy();
// }
